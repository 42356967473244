import Vue from 'vue'
import Router from 'vue-router'
import MainFrame from '@/components/MainFrame'
import Login from '@/components/Login'

//数据面板
import Dashboard from '@/components/Dashboard/Dashboard'
import OrderList from '@/components/Order/orderList'
import StoreSet from '@/components/Store/StoreSet'
import BannerSet from '@/components/Store/BannerSet'
import CateList from '@/components/Goods/CateList'
import GoodsList from '@/components/Goods/GoodsList'


import App from '@/App.vue'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    // 处理错误 跳转到重复路径上，忽略错误
    // console.error(err);
  });
};

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'App',
      component: App,
	  children:[
		  {
			path:'',
			component: MainFrame,
		  },
		  {
			name: 'Home',
			path: 'Home',
			component: MainFrame,
			children:[
				{
					path:'/Dashboard',
					name:'Dashboard',
					component:Dashboard
				},{
					path:'/OrderList',
					name:'OrderList',
					component:OrderList
				},{
					path:'/StoreSet',
					name:'StoreSet',
					component:StoreSet
				},{
					path:'/BannerSet',
					name:'BannerSet',
					component:BannerSet
				},{
					path:'/CateList',
					name:'CateList',
					component:CateList
				},{
					path:'/GoodsList',
					name:'GoodsList',
					component:GoodsList
				}
			]
		  }
	  ]
    },{
      path: '/Login',
      name: 'Login',
      component: Login
    }
  ]
})
