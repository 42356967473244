<template>
	<div id="body">
		<div class="body_content">
			<div style="background-color: white; padding: 10px; border-radius: 10px; ">
				<div></div>
				<el-form ref="form" :model="form" label-width="180px" style="width: 700px;" >
					
					<el-form-item label="产品名称">
						<el-input v-model="form.goods_name"></el-input>
					</el-form-item>
					
					<el-form-item label="产品副标题">
						<el-input v-model="form.goods_title"></el-input>
					</el-form-item>
					
					<el-form-item label="产品主图">
						<el-upload
						  class="avatar-uploader"
						  action="https://gold-api.easy2.cn/Upload/storePhoto?types=other"
						  :show-file-list="false"
						  :on-success="UploadSuccess"
						  :before-upload="beforeUpload">
						  <img v-if="form.img_url" :src="form.img_url" class="avatar" style="width: 200px;">
						  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item>
					
					
					<el-form-item label="产品描述">
						<el-input v-model="form.goods_desc"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="save">确认添加</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	
	export default {
		name:'GoodsAdd',
		data(){
			return {
				baidu:'https://api.map.baidu.com/lbsapi/getpoint/',
				form:{
					goods_name:'',
					img_url:'',
					goods_title:'',
					goods_desc:'',
					status:true
				}
			}
		},
		mounted() {
		},
		methods:{
			UploadSuccess(e){
				console.log("UploadSuccess", e);
				if(e.code == 1)
				{
					this.form.img_url = e.data.file;
				}
				this.$message({
					 message: '上传成功',
					 type: 'success',
				});
			},
			beforeUpload(e){
				console.log("beforeUpload", e);
			},
			save(){
				let that = this;
				
				if(this.form.goods_name.trim().length < 1 ||
					this.form.img_url.trim().length < 1 || 
					this.form.goods_title.trim().length < 1 ||
					 this.form.goods_desc.trim().length < 1 )
					{
						return that.$message({
							 message: '请填写完整信息',
							 type: 'warning',
						});
					}
				
				
				let loading = this.$loading({
						lock: true,
						text: '添加中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				
				that.$api.post("/Goods/addGoods", this.form).then(res=>{
					if(res.code == 1)
					{
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(err=>{
					loading.close()
				});
			}
		}
	}
</script>

<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	.body_content {
		padding: 10px;
	}

</style>