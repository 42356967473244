<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; justify-content: space-between; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<div style="display: flex; flex-direction: row; align-items: center;">
				<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
				<el-breadcrumb separator-class="el-icon-arrow-right">
				  <el-breadcrumb-item>门店设置</el-breadcrumb-item>
				  <el-breadcrumb-item>门店信息</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="body_content">
			<div style="background-color: white; padding: 10px; border-radius: 10px; ">
				<div></div>
				<el-form ref="form" :model="form" label-width="180px" style="width: 700px;" >
					
					<el-form-item label="门店名称">
						<el-input v-model="form.store_name"></el-input>
					</el-form-item>
					<el-form-item label="门店地址">
						<el-input v-model="form.store_addr"></el-input>
					</el-form-item>
					<el-form-item label="营业时间">
						<el-input v-model="form.store_time"></el-input>
					</el-form-item>
					<el-form-item label="经度">
						<el-input v-model="form.latitude"></el-input>
					</el-form-item>
					<el-form-item label="纬度">
						<el-input v-model="form.longitude"></el-input>
					</el-form-item>
					<el-form-item label="经纬度获取">
						<el-input v-model="baidu" disabled></el-input>
					</el-form-item>
					<el-form-item label="门店照片">
						<el-upload
						  class="avatar-uploader"
						  action="https://gold-api.easy2.cn/Upload/storePhoto?types=store_photo"
						  :show-file-list="false"
						  :on-success="UploadSuccess"
						  :before-upload="beforeUpload">
						  <img v-if="form.store_photo" :src="form.store_photo" class="avatar" style="width: 200px;">
						  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="简介头图">
						<el-upload
						  class="avatar-uploader"
						  action="https://gold-api.easy2.cn/Upload/storePhoto?types=contact_photo"
						  :show-file-list="false"
						  :on-success="UploadSuccess"
						  :before-upload="beforeUpload">
						  <img v-if="form.contact_photo" :src="form.contact_photo" class="avatar" style="width: 200px;">
						  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="店铺详细介绍">
						<el-input v-model="form.store_desc"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="save">保存设置</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	
	export default {
		name:'StoreSet',
		data(){
			return {
				baidu:'https://api.map.baidu.com/lbsapi/getpoint/',
				form:{
					status:true
				}
			}
		},
		mounted() {
			this.getData();
		},
		methods:{
			UploadSuccess(e){
				console.log("UploadSuccess", e);
				if(e.data.type == "store_photo")
				{
					this.form.store_photo = e.data.file;
				}
				if(e.data.type == "contact_photo")
				{
					this.form.contact_photo = e.data.file;
				}
				this.$message({
					 message: '上传成功',
					 type: 'success',
				});
			},
			beforeUpload(e){
				console.log("beforeUpload", e);
			},
			refresh(){
				this.getData();
			},
			save(){
				let that = this;
				let loading = this.$loading({
						lock: true,
						text: '保存中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				
				that.$api.post("/System/saveStoreSetting", this.form).then(res=>{
					if(res.code == 1)
					{
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(err=>{
					loading.close()
				});
			},
			getData(){
				let that = this;
				let loading = this.$loading({
						lock: true,
						text: '获取数据中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				
				that.$api.post("/System/getStoreSetting", {}).then(res=>{
					if(res.code == 1)
					{
						that.form = res.data;
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
					loading.close()
				}).catch(err=>{
					loading.close()
				});
			}
		}
	}
</script>

<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	.body_content {
		padding: 10px;
	}

</style>