var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"body"}},[_c('div',{staticStyle:{"box-shadow":"1px 1px 10px #DCDCDC","height":"40px","background-color":"white","display":"flex","flex-direction":"row","justify-content":"space-between","margin":"0 10px","padding":"5px 5px 5px 20px","border-radius":"10px"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"center"}},[_c('el-button',{staticStyle:{"margin-right":"20px"},attrs:{"icon":"el-icon-refresh-right","circle":""},on:{"click":_vm.refresh}}),_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',[_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v("数据看板")])],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"data-box"},[_vm._m(0),_c('div',{staticClass:"data-line"},[_c('div',{staticClass:"ckey"},[_vm._v(_vm._s(_vm.static.today))]),_c('div',{staticClass:"cvalue"},[_vm._v("今日咨询")])])]),_c('div',{staticClass:"data-box"},[_vm._m(1),_c('div',{staticClass:"data-line"},[_c('div',{staticClass:"ckey"},[_vm._v(_vm._s(_vm.static.yesterday))]),_c('div',{staticClass:"cvalue"},[_vm._v("昨日咨询")])])]),_c('div',{staticClass:"data-box"},[_vm._m(2),_c('div',{staticClass:"data-line"},[_c('div',{staticClass:"ckey"},[_vm._v(_vm._s(_vm.static.week))]),_c('div',{staticClass:"cvalue"},[_vm._v("本周咨询")])])]),_c('div',{staticClass:"data-box"},[_vm._m(3),_c('div',{staticClass:"data-line"},[_c('div',{staticClass:"ckey"},[_vm._v(_vm._s(_vm.static.month))]),_c('div',{staticClass:"cvalue"},[_vm._v("本月咨询")])])]),_c('div',{staticClass:"data-box"},[_vm._m(4),_c('div',{staticClass:"data-line"},[_c('div',{staticClass:"ckey"},[_vm._v(_vm._s(_vm.static.total))]),_c('div',{staticClass:"cvalue"},[_vm._v("合计咨询")])])])]),_c('div',{staticClass:"row"}),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-icon cl_green"},[_c('i',{staticClass:"el-icon-user-solid"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-icon cl_green"},[_c('i',{staticClass:"el-icon-user-solid"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-icon cl_red"},[_c('i',{staticClass:"el-icon-user-solid"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-icon cl_red"},[_c('i',{staticClass:"el-icon-user-solid"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-icon cl_orange"},[_c('i',{staticClass:"el-icon-user-solid"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"white","margin":"10px","display":"flex","flex-direction":"row","padding-top":"20px"}},[_c('div',{staticStyle:{"width":"100%","height":"400px"},attrs:{"id":"chart"}})])
}]

export { render, staticRenderFns }