<style scoped>
	#body {
		margin: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
	}
	
	.el-form-item {
		margin-bottom: 0px;
	}
	
</style>

<template>
	<div id="body">
		<div style="box-shadow: 1px 1px 10px #DCDCDC; height: 40px; background-color: white; display: flex; flex-direction: row; align-items: center; margin: 0 10px; padding: 5px 5px 5px 20px; border-radius: 10px;">
			<el-button icon="el-icon-refresh-right" circle  @click="refresh" style="margin-right: 20px;"></el-button>
			
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item>首页</el-breadcrumb-item>
			  <el-breadcrumb-item>产品管理</el-breadcrumb-item>
			  <el-breadcrumb-item>产品列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top: 10px; background-color: white; padding: 10px; margin: 10px; border-radius: 5px;">
			<div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; margin-bottom: 10px;">
				<!-- <div style="height: 40px; line-height: 40px;">用户列表</div> -->
				<el-form :inline="true" class="demo-form-inline">
					
					<el-form-item label="" style="width: 120px;">
						<el-select @change="statusChange" v-model="status" placeholder="所有状态" clearable>
							<el-option label="所有状态" value="-1"></el-option>
							<el-option label="显示中" value="1"></el-option>
							<el-option label="暂停中" value="0"></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="">
						<el-input v-model="keyword" placeholder="说明"></el-input>
					</el-form-item>
					
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索查询</el-button>
					</el-form-item>
					
					<el-form-item>
						<el-button type="danger" @click="addBanner">添加产品</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" ref="myTable" border  style="width: 100%" 
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" >
				
				<el-table-column prop="gid" label="编号" width="120"  align="center">
				</el-table-column>
	
				<el-table-column prop="img_url" label="产品主图" width="300"  align="center">
					<template slot-scope="scope">
						<el-image style="width: 200px; height: 200px" :src="scope.row.goods_main_imgs[0]" fit="contain"></el-image>
					</template>
				</el-table-column>
				
				<el-table-column prop="goods_name" label="产品名称" width="250"  align="center">
				</el-table-column>
				
				<el-table-column prop="goods_title" label="产品副标题" width="400"  align="center">
				</el-table-column>
				

				<el-table-column prop="pay_status" label="显示" align="center"  width="120">
					<template slot-scope="scope">
						<el-button @click="status_func(scope.row)" v-if="scope.row.status==0" circle>否</el-button>
						<el-button @click="status_func(scope.row)" v-if="scope.row.status!=0" type="primary" circle>是</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="pay_status" label="首页热门" align="center"  width="120">
					<template slot-scope="scope">
						<el-button @click="top_func(scope.row)" v-if="scope.row.is_top==0" circle>否</el-button>
						<el-button @click="top_func(scope.row)" v-if="scope.row.is_top!=0" type="primary" circle>是</el-button>
					</template>
				</el-table-column>
				
				<el-table-column prop="img_url" label="抖音二维码" width="150"  align="center">
					<template slot-scope="scope">
						<el-image style="width: 100px; height: 100px" :src="scope.row.qrcode" fit="contain"></el-image>
					</template>
				</el-table-column>
				
				<el-table-column fixed="right" label="操作" width="200" align="center">
					<template slot-scope="scope">
						<el-button type="primary" @click="editGoods(scope.row)"  size="mini" >编辑查看</el-button>
						<el-button type="danger" @click="deleteGoods(scope.row, scope.index)"  size="mini" >删除</el-button>
					</template>
				</el-table-column>
				
			</el-table>
			<el-pagination background layout="prev, pager, next" :key="tableKey" :page-size="limit" @current-change="getData" :total="total" style="margin-top: 20px;"></el-pagination>
		</div>
		
		<el-dialog title="添加产品" :visible.sync="actionOrderView" width="900px" @close="actionOrderViewClose">
			<GoodsAdd></GoodsAdd>
		</el-dialog>
		
	</div>
</template>

<script>
	import GoodsAdd from './GoodsAdd.vue';
	export default {
		name:'List',
		components:{
			GoodsAdd
		},
		data(){
			return {
				/* 操作订单控制参数 */
				actionOrderView:false,
				actionOrderTitle:'订单操作',
				actionOrderInfo:{},
				/* 搜索参数 */
				payway:'',
				status:"-1",
				keyword:'',
				/* 表格参数 */
				tableKey:0,
				loading:false,
				limit:10,
				total:0,
				tableData: []
			}
		},
		created() {
			this.getData();
		},
		methods:{
			actionOrderViewClose(){
				//
			},
			addBanner(){
				this.actionOrderView = true;
			},
			top_func(row){
				const loading = this.$loading({
						lock: true,
						text: '执行中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				let _new_status = 0;
				if(row.is_top == 0)
				{
					_new_status = 1;
				}else{
					_new_status = 0;
				}
				
				that.$api.post("/Goods/goodsTopChange", {gid:row.gid, is_top:_new_status}).then(res=>{
					setTimeout(()=>{loading.close()}, 100);
					if(res.code == 1)
					{
						that.tableData.forEach((v,k)=>{
							if(v.gid == row.gid)
							{
								that.tableData[k].is_top = _new_status;
							}
						});
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			status_func(row){
				const loading = this.$loading({
						lock: true,
						text: '执行中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				let _new_status = 0;
				if(row.status == 0)
				{
					_new_status = 1;
				}else{
					_new_status = 0;
				}
				
				that.$api.post("/Goods/goodsStatusChange", {gid:row.gid, status:_new_status}).then(res=>{
					setTimeout(()=>{loading.close()}, 100);
					if(res.code == 1)
					{
						that.tableData.forEach((v,k)=>{
							if(v.gid == row.gid)
							{
								that.tableData[k].status = _new_status;
							}
						});
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},
			deleteGoods(row, index){
				let that = this;
				this.$confirm('是否确认删除此产品?', '提示', {
					confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
				}).then(() => {
					that.deleteGoodsAction(row, index);
				}).catch(() => {
				});	
			},
			deleteGoodsAction(row, index){
				const loading = this.$loading({
						lock: true,
						text: '删除中',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				let that = this;	
				that.$api.post("/Goods/deleteGoods", {gid:row.gid}).then(res=>{
					setTimeout(()=>{loading.close()}, 200);
					if(res.code == 1)
					{
						that.tableData.splice(index, 1);
						that.$message({
							 message: res.msg,
							 type: 'success',
						});
					}else{
						that.$message({
							 message: res.msg,
							 type: 'warning',
						});
					}
				}).catch(res=>{
					loading.close();
				});
			},


			statusChange(e){
				this.status = e;
				this.getData(1);
			},
			refresh(){
				this.getData();
			},
			getData(page=1){
				let that = this;
				that.loading = true;
				that.$api.post("/Goods/getGoodsList", {page:page, status:that.status, keyword:that.keyword}).then(res=>{
					that.loading = false;
					that.tableData = res.data.list;
					that.total = res.data.total;
				}).catch(res=>{
					that.loading = false;
				});
			},
			
			onSubmit(){
				this.getData();
			}
		}
	}
</script>