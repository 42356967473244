import { render, staticRenderFns } from "./GoodsList.vue?vue&type=template&id=4911aabd&scoped=true"
import script from "./GoodsList.vue?vue&type=script&lang=js"
export * from "./GoodsList.vue?vue&type=script&lang=js"
import style0 from "./GoodsList.vue?vue&type=style&index=0&id=4911aabd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4911aabd",
  null
  
)

export default component.exports