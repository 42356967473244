import Vue from 'vue'
import App from './App.vue'
// 引入elementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
Vue.use(ElementUI);
// 引入vue-router
import router from './router'
// 引入store
import store from './store'
Vue.prototype.$store = store
store.commit('recoverState');

// 引入axios 
import api from './axios';
Vue.prototype.$api = api
// 开发模式
Vue.config.productionTip = false



router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !store.state.login_status) next({ name: 'Login' })
  else next()
});


new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
